import axios from 'axios';
// import { cacheAdapterEnhancer } from 'axios-extensions';
import { isWebUri } from 'valid-url';
import { logoutUser } from '../redux/actions/user';
import { loadTimeTracker } from '../utils/custom-hooks/useLoadTimeTracker';
import { captureException } from '@sentry/react';

const UNAUTHORIZED_ERROR_CODE = 401;

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  authorization: 'Basic NmMwOGViNGYxMTE4NGVmNGFkZTM2YTFiYjgyMjAwNDE6'
};

export const getAuthHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

const urlToOverride = localStorage.getItem('baseURL');
let baseURL = process.env.REACT_APP_API_URL;

if (isWebUri(urlToOverride)) {
  baseURL = urlToOverride;
}

export const request = axios.create({
  baseURL,
  headers: { 'Cache-Control': 'no-cache' }
  // cache will be enabled by default
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
  //   enabledByDefault: false,
  //   cacheFlag: 'useCache'
  // })
  // timeout: 1000,
  // headers
});


request.interceptors.request.use(
  config => {
    if (config.url.includes('/v2/orgs?attributes=workspaces&include_extended=true&workspaces=')) {
      loadTimeTracker(true, 'my-organizations', false);
    }
    if (config.url.includes('region-tree?reference_date=')) {
      loadTimeTracker(true, 'landing-organization (fields)', false);
    }
    if (config.url.includes('/properties?attributes=total_area')) {
      loadTimeTracker(true, 'landing-organization (farms)', false);
    }
    if (config.url.includes('v2/roles')) {
      loadTimeTracker(true, 'edit member', false);
    }
    if (config.url.includes('/v2/accounts/batch/invitation')) {
      loadTimeTracker(true, 'invite member', false);
    }
    return config;
  },
  function (error) {
    captureException(error, {
      extra: {
        file: 'api.config.ts',
        method: 'api (axios) interceptors request',
      },
    });

    return Promise.reject(error);
  }
);

//Interceptor to handle 401 Unauthorized error
request.interceptors.response.use(
  (response) => {

    if (response.config.url.includes('/v2/orgs?attributes=workspaces&include_extended=true&workspaces=')) {
      loadTimeTracker(false, 'my-organizations', false);
    }
    if (response.config.url.includes('region-tree?reference_date=')) {
      loadTimeTracker(false, 'landing-organization (fields)', false);
    }
    if (response.config.url.includes('/properties?attributes=total_area')) {
      loadTimeTracker(false, 'landing-organization (farms)', false);
    }
    if (response.config.url.includes('v2/roles')) {
      loadTimeTracker(false, 'edit member', false);
    }
    if (response.config.url.includes('/v2/accounts/batch/invitation')) {
      loadTimeTracker(false, 'invite member', false);
    }
    return response;
  },
  (error) => {
    captureException(error, {
      extra: {
        file: 'api.config.ts',
        method: 'api (axios) interceptors request',
      },
    });
    
    if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
      logoutUser();
    }

    return Promise.reject(error);
  }
);
